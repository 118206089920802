import React from "react"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import ImagesModule from "../images"
import CTA from "../elements/cta-button"


export default ({ style, className }) => {

    const { Picture } = ImagesModule()

    const { t } = useTranslation(['sections', 'slugs'])
    const tDualSplit = t('dual_split')
    const tSlugs = t('slugs:slugs')

    return (
        <section className={["section", className].join(" ")} style={style}>
            <div className="container has-text-centered is-medium">
                <h2 className="title is-2 is-uppercase pb-2">
                    {tDualSplit.title}
                </h2>
                <div className="columns is-variable is-4 is-centered">
                    <div className="column is-5 is-flex">
                        <div className="box has-background-light">
                            <Picture name="team_buildings" className="mb-5" alt={tDualSplit.teambuildings.image_alt} />
                            <h3 className="title is-3 is-uppercase">{tDualSplit.teambuildings.title}</h3>
                            <p className="mb-2">
                                <Trans
                                    i18nKey={tDualSplit.teambuildings.p1}
                                    components={{ b: <b /> }}
                                />
                            </p>
                            <p className="mb-2">
                                {tDualSplit.teambuildings.p2}
                            </p>
                            <p className="mb-5">
                                {tDualSplit.teambuildings.p3}
                            </p>
                            <CTA
                                to={tSlugs["/teambuildings/"]}
                                className="mb-4"
                            >
                                {tDualSplit.teambuildings.cta}
                            </CTA>
                        </div>
                    </div>
                    <div className="column is-5 is-flex">
                        <div className="box has-background-light">
                            <Picture name="seminaires_et_congres" className="mb-5" alt={tDualSplit.seminaries.image_alt} />
                            <h3 className="title is-3 is-uppercase">
                                {tDualSplit.seminaries.title}
                            </h3>
                            <p className="mb-2">
                                <Trans
                                    i18nKey={tDualSplit.seminaries.p1}
                                    components={{ b: <b /> }}
                                />
                            </p>
                            <p className="mb-2">
                                {tDualSplit.seminaries.p2}
                            </p>
                            <p className="mb-5">
                                <Trans
                                    i18nKey={tDualSplit.seminaries.p3}
                                    components={{ b: <b /> }}
                                />
                            </p>
                            <CTA
                                to={tSlugs["/seminaires-et-congres/"]}
                                className="mb-4"
                            >

                                {tDualSplit.seminaries.cta}
                            </CTA>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}